<template>
    <div class="form-input-wrapper" @focusout="hideOptions" tabindex="1">
        <label>
            <span class="form-input-label">{{ $t(data.label) }}</span>
            <input
                class="form-input form-select"
                :class="validationClass"
                type="text"
                ref="inputElement"
                :placeholder="$t(data.placeholder ?? '')"
                v-model="query"
                @click="toggleSelect"
                :readonly="readonly"
            />
        </label>
        <div class="select-list" v-show="selectActive">
            <ul>
                <template v-for="option in data.options">
                    <li 
                        class="select-list-item" 
                        @click="selectOption(option)"
                    >
                        {{ $t(option.label) ?? option.label }}
                    </li>
                </template>
            </ul>
        </div>
        <span class="form-error-message" v-if="touched && !valid && !selectActive">{{ $t(data.errorMessage) }}</span>
    </div>
</template>

<script setup>
import {computed, nextTick, onMounted, ref, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useGeneralStore} from "../../../store/general";

const props = defineProps({ 
    data: {
        type: Object,
        required: true
    },
    value: {
        type: [String, Number],
        default: ''
    },
    valid: {
        type: Boolean,
        default: false
    },
    touched: {
        type: Boolean,
        default: false
    },
  readonly: {
      type: Boolean,
      default: true
  }
});

const emit = defineEmits(['update:value', 'update:valid', 'update:touched']);

const i18n = useI18n();
const store = useGeneralStore();

const query = ref(getLabelByValue());
const inputElement = ref();
const selectActive = ref(false);

const validationClass = computed(() => {
    if (props.touched && !props.valid) {
        return '--invalid';
    } else {
        return '';
    }
});

function getLabelByValue() {
  if (props.value !== '') {
   return i18n.t(props.data.options?.find((option) =>  option.value === props.value)?.label ?? '') ?? '';
  }
  return '';
}

function validateField() {
  if (props.value) {
      emit('update:valid', true);
  } else {
      emit('update:valid', false);
  }
}

function selectOption(option) {
  query.value = i18n.t(option.label);
  selectActive.value = false;

  emit('update:valid', true);
  emit('update:value', option.value);
}

function hideOptions(event) {
    const currentTargetElement = event.currentTarget;
    const relatedTargetElement = event.relatedTarget;

    if (
        !(currentTargetElement === relatedTargetElement)
        && !relatedTargetElement?.className.includes('select-list')
    ) {
        selectActive.value = false;
        
        query.value = getLabelByValue();
        validateField();
        emit('update:touched', true);
    }
}

function toggleSelect() {
    nextTick(() => {
        inputElement.value.focus();
        selectActive.value = !selectActive.value;

        if (!selectActive.value) {
            query.value = getLabelByValue();
        }
    })
}

onMounted(() => {
    if (props.value !== '' && props.data.options?.find((option) => option.value === props.value)) {
        emit('update:touched', true);
        emit('update:valid', true);
    } else {
        emit('update:touched', false);
        emit('update:valid', false);
    }
});

watch(i18n.locale, () => {
  query.value = getLabelByValue();
});
</script>