<template>
    <footer class="footer">
        <div class="container">
            <div class="footer-wrapper">
                <img src="/assets/icon/evisa-express-logo-dark-theme.svg" class="footer-logo">
                <span class="footer-text">
                    © {{ currentYear }} {{ $t('system.copyrights') }}
                </span>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { computed } from 'vue';

const currentYear = computed(() => {
    return new Date().getFullYear();
})
</script>