const messages = {
    en: {
        system: {
            backToEvisaExpress: 'Back to evisa.express',
            copyrights: 'Evisa Express. All right reserved. The Evisa Express® is a registered trademark.',
            contactUs: 'Contact us'
        },
        '404': {
            banner : {
                title: '404',
                subtitle: 'Page not found',
            },
            content: 'The requested page was not found on this server.',
        },
        languageSwitcher: {
            en: 'English',
            de: 'Deutsch',
            es: 'Español',
            pl: 'Polski',
            cn: '中文',
            fr: 'Français',
            title: 'Language settings',
            selectLabel: 'Language',
            save: 'Save and apply',
            cancel: 'Cancel'
        },
        form: {
            title: 'Check status of your visa',
            description: 'If you had applied for visa, you can check the application status',
            idStr: 'Application ID',
            idStrPlaceholder: 'Your application ID',
            idStrErrorMessage: 'Application ID cannot be empty',
            dayOfBirth: 'Day of birth',
            dayOfBirthPlaceholder: 'Day',
            dayOfBirthErrorMessage: 'Enter day',
            monthOfBirth: 'Month of birth',
            monthOfBirthPlaceholder: 'Month',
            monthOfBirthErrorMessage: 'Enter month',
            yearOfBirth: 'Year of birth',
            yearOfBirthPlaceholder: 'Year',
            yearOfBirthErrorMessage: 'Enter year',
            surname: 'Surname(s)',
            surnamePlaceholder: 'Your Surname(s)',
            surnameErrorMessage: 'Surname(s) cannot be empty',
            buttonCheckStatus: 'Check status',
            January: 'January',
            February: 'February',
            March: 'March',
            April: 'April',
            May: 'May',
            June: 'June',
            July: 'July',
            August: 'August',
            September: 'September',
            October: 'October',
            November: 'November',
            December: 'December',
            not_found: {
                title: 'Application not found',
                description: 'No applications found with these details, please double check the entered data and try again.'
            },
            forbidden: {
                title: 'Request limit exceeded',
                description: 'Request limit exceeded, please try again later.'
            },
        },
        status: {
            title: 'Your application status',
            back: 'Back',
            status: 'Status:',
            appNo: 'Application number',
            appDate: 'Date of application',
            pay: 'Make a payment',
            unpaid: {
                title: '<span class="text-orange">Application unpaid</span>',
                description: 'Your application is unpaid and cannot be processed until we receive a payment from you. Please use the following link to complete a payment: {link}'
            },
            'status-0': {
                title: '<span class="text-blue-title">Queued for processing</span>',
                description: 'The application is being reviewed by our specialist and in the queue for being processed. If we need any additional information from you, we will get in touch.'
            },
            'status-1': {
                title: '<span class="text-blue-title">We are processing your application</span>',
                description : 'Your application is being processed by the visas & immigration authorities. As soon as it is processed a decision will be sent to you via email. No further action is required from you.'
            },
            'status-2': {
                title: '<span class="text-green">Your application is approved</span>',
                description: 'Your application has been successfully approved and the confirmation has been sent to you via email. If you can’t find your confirmation, please make sure that you check your SPAM folder.'
            },
            'status-3': {
                title: '<span class="text-green">Your refund is on the way</span>',
                description: 'Your refund has been approved and is being processed by our refunds team. Depending on your bank, the refund process might take up to 5 business days. You will receive an email once the refund is sent.'
            },
            'status-4': {
                title: '<span class="text-green">Your refund is complete</span>',
                description: 'Your refund has been successfully processed and should be back in your bank account in the next few days. We have also sent a refund confirmation to you via email.'
            },
            'status-5': {
                title: '<span class="text-orange">Your application is incomplete</span>',
                description: 'Before we can start to process your application, we need you to provide all of the required information and supporting documents. In order to finish the application, please log in to your evisa.express account at {link}. If you don’t have an evisa.express account, please register using the same email address you have used during the application process and complete your application after logging in.'
            },
            'status-7': {
                title: '<span class="text-blue-title">Your application is on hold</span>',
                description: 'Since your application needs to be processed closer to your arrival date according to the government regulations, we have temporarily placed your application on hold. Don’t worry - we will resume processing your application in a timely manner to ensure that it is ready for you when your journey starts. <br /><br />The process is automatic and no further action is required from you. You will also receive an email with the decision after your application is processed.'
            },
            'status-8': {
                title: '<span class="text-orange">Missing documents</span>',
                description: 'We need supporting documents before we can start to process your application. Please upload your supporting documents using our secure portal following this link: {link}.'
            },
            'status-9': {
                title: '<span class="text-red">Cancelled</span>',
                description: 'Your application has been canceled and all of your data has been removed from our databases.'
            },
            'status-10': {
                title: '<span class="text-red">Application refused</span>',
                description: 'Your application has been refused by the visas & immigration authorities, which is outside of our control. Please contact your nearest embassy for more details.'
            },
            test_mode: {
                title: '<span class="text-blue-title">Marked as a test application: (status: {status})</span>',
                description: "Your application has been marked as a ‘test application’ and will not be processed. If this is incorrect please contact <a class='status-link status-link-burgund' href='mailto:{supportEmail}'>{supportEmail}.</a>"
            },
            underpayment: {
                title: '<span class="text-orange">Additional payment is required</span>',
                description: 'Your application {appId} requires additional payment before it can be processed. Use the button below to make a payment.'
            },
            linkPayment: 'payment link'
        }
    },
    de: {
        system: {
            backToEvisaExpress: 'Zurück zu evisa.express',
            copyrights: 'Evisa Express. Alle Rechte vorbehalten. Evisa Express® ist eine eingetragene Marke.',
            contactUs: 'Kontakt'
        },
        languageSwitcher: {
            en: 'English',
            de: 'Deutsch',
            es: 'Español',
            pl: 'Polski',
            cn: '中文',
            fr: 'Français',
            title: 'Spracheinstellungen',
            selectLabel: 'Sprache',
            save: 'Speichern und anwenden',
            cancel: 'Abbrechen'
        },
        form: {
            title: 'Überprüfen Sie den Status Ihres Visums',
            description: 'Wenn Sie ein Visum beantragt haben, können Sie hier den Status Ihres Antrags überprüfen',
            idStr: 'Ihre Antragsnummer',
            idStrPlaceholder: 'Ihre Anwendungs-ID',
            idStrErrorMessage: 'Die Anwendungs-ID darf nicht leer sein',
            dayOfBirth: 'Geburtsdatum',
            dayOfBirthPlaceholder: 'Tag',
            dayOfBirthErrorMessage: 'Tag eingeben',
            monthOfBirth: 'Geburtsdatum',
            monthOfBirthPlaceholder: 'Monat',
            monthOfBirthErrorMessage: 'Monat eingeben',
            yearOfBirth: 'Geburtsdatum',
            yearOfBirthPlaceholder: 'Jahr',
            yearOfBirthErrorMessage: 'Jahr eingeben',
            surname: 'Nachname(n)',
            surnamePlaceholder: 'Ihr(e) Nachname(n)',
            surnameErrorMessage: 'Nachname(n) dürfen nicht leer sein',
            buttonCheckStatus: 'Status prüfen',
            January: 'Januar',
            February: 'Februar',
            March: 'März',
            April: 'April',
            May: 'Mai',
            June: 'Juni',
            July: 'Juli',
            August: 'August',
            September: 'September',
            October: 'Oktober',
            November: 'November',
            December: 'Dezember',
            not_found: {
                title: 'Anwendung nicht gefunden',
                description: 'Es wurden keine Bewerbungen mit diesen Angaben gefunden. Bitte überprüfen Sie die eingegebenen Daten und versuchen Sie es erneut.'
            },
            forbidden: {
                title: 'Anforderungslimit überschritten',
                description: 'Anforderungslimit überschritten, bitte versuchen Sie es später erneut.'
            }
        },
        status: {
            title: 'Ihr Bewerbungsstatus',
            back: 'Zurück',
            status: 'Status:',
            appNo: 'Nummer des Antrags',
            appDate: 'Datum der Anmeldung',
            pay: 'Eine Zahlung leisten',
            unpaid: {
                title: '<span class="text-orange">Antrag unbezahlt</span>',
                description: 'Ihr Antrag ist unbezahlt und kann erst dann bearbeitet werden, nachdem wir eine Zahlung von Ihnen erhalten haben. Bitte verwenden Sie den folgenden Link, um die Zahlung zu tätigen: { link }.'
            },
            'status-0': {
                title: '<span class="text-blue-title">In der Warteschlange für die Bearbeitung</span>',
                description: 'Ihr Antrag wird von unseren Experten geprüft und befindet sich in der Warteschlange zur Bearbeitung. Sollten zusätzliche Informationen benötigt werden, setzen wir uns mit Ihnen in Verbindung.'
            },
            'status-1': {
                title: '<span class="text-blue-title">Wir bearbeiten Ihren Antrag</span>',
                description : 'Ihr Antrag wird zur Zeit von den Einwanderungsbehörden überprüft. Sobald eine Entscheidung folgt, wird diese an Ihre E-Mail-Adresse weitergeleitet. Keine weiteren Maßnahmen sind Ihrerseits erforderlich.'
            },
            'status-2': {
                title: '<span class="text-green">Ihr Antrag wurde bewilligt</span>',
                description: 'Ihr Antrag wurde bewilligt und die Bestätigung an Ihre E-Mail-Adresse geschickt. Wenn Sie die Bestätigung nicht finden können, überprüfen Sie bitte Ihren SPAM-Ordner.'
            },
            'status-3': {
                title: '<span class="text-green">Ihr Antrag wird in Kürze rückerstattet</span>',
                description: 'Ihr Antrag wurde storniert. Die Rückerstattung der Zahlung wird für Sie demnächst eingeleitet. Je nach Bank mag die Rücküberweisung bis zu 5 Werktage dauern. Sie erhalten eine E-Mail, sobald die Rückerstattung veranlasst worden ist.'
            },
            'status-4': {
                title: '<span class="text-green">Die Rückerstattung wurde veranlasst</span>',
                description: 'Die Rückerstattung wurde bereits veranlasst. Das Geld sollte in den nächsten Tagen wieder auf Ihrem Bankkonto eingehen. Außerdem haben wir Ihnen eine E-Mail mit der Rückerstattungsbestätigung geschickt.'
            },
            'status-5': {
                title: '<span class="text-orange">Ihr Antrag ist unvollständig</span>',
                description: 'Bevor wir mit der Bearbeitung des Antrags beginnen können, benötigen wir von Ihnen alle erforderlichen Informationen sowie Unterlagen. Um den Antrag abzuschließen, melden Sie sich bitte bei Ihrem evisa.express-Konto unter {link} an. Wenn Sie noch kein evisa.express-Konto haben, registrieren Sie sich bitte mit der gleichen E-Mail-Adresse, die Sie während des Antragsverfahrens verwendet haben, und vervollständigen Ihren Antrag nach dem Einloggen.'
            },
            'status-7': {
                title: '<span class="text-blue-title">Die Bearbeitung Ihres Antrags wurde eingestellt</span>',
                description: 'Da Ihr Antrag gemäß geltender Visa-Regelungen näher am Reisedatum bearbeitet werden muss, haben wir die Bearbeitung Ihres Antrags vorübergehend eingestellt. <br /><br />Bitte machen Sie sich keine Sorgen - wir werden die Bearbeitung Ihres Antrags rechtzeitig aufnehmen, damit Sie Ihr Visum bzw. Ihre Einreisegenehmigung noch vor Reiseantritt erhalten. Der Vorgang ist automatisch; Sie brauchen nichts weiter zu unternehmen. Nachdem Ihr Antrag bearbeitet worden ist, erhalten eine E-Mail mit der Entscheidung.'
            },
            'status-8': {
                title: '<span class="text-orange">Fehlende Unterlagen</span>',
                description: 'Bevor wir mit der Bearbeitung Ihres Antrags beginnen können, benötigen wir von Ihnen weitere Unterlagen. Bitte laden Sie die Unterlagen sicher über unser Portal hoch, indem Sie diesem Link folgen: {link}.'
            },
            'status-9': {
                title: '<span class="text-red">Gelöscht</span>',
                description: 'Ihr Antrag wurde gelöscht und all Ihre Daten aus unserer Datenbak entfernt.'
            },
            'status-10': {
                title: '<span class="text-red">Antrag abgelehnt</span>',
                description: 'Ihr Antrag wurde seitens der Einwanderungsbehörden abgelehnt, worauf wir leider keinen Einfluss haben. Für weitere Informationen kontaktieren Sie bitte die nächstgelegene Botschaft.'
            },
            test_mode: {
                title: '<span class="text-blue-title">Als Testlauf markiert: (status: {status})</span>',
                description: "Ihr Antrag wurde als ‘Testlauf’ markiert und wird nicht bearbeitet. Sollte dies inkorrekt sein, wenden Sie sich bitte an <a class='status-link status-link-burgund' href='mailto:{supportEmail}'>{supportEmail}.</a>"
            },
            underpayment: {
                title: '<span class="text-orange">Zuzahlung erforderlich</span>',
                description: 'Ihr Antrag {appId} erfordert eine zusätzliche Zahlung, bevor dieser bearbeitet werden kann. Drücken Sie auf die untenstehende Taste, um die Zuzahlung vorzunehmen.'
            },
            linkPayment: 'Link zur Zahlung'
        }
    },
    es: {
        system: {
            backToEvisaExpress: 'Volver a evisa.express',
            copyrights: 'Evisa Express. Todos los derechos reservados. Evisa Express® es una marca registrada.',
            contactUs: 'Contáctenos',
        },
        languageSwitcher: {
            en: 'English',
            de: 'Deutsch',
            es: 'Español',
            pl: 'Polski',
            cn: '中文',
            fr: 'Français',
            title: 'Ajustes de idioma',
            selectLabel: 'Idioma',
            save: 'Guardar y aplicar',
            cancel: 'Cancelar'
        },
        form: {
            title: 'Compruebe el estatus de su visado',
            description: 'Si ha solicitado un visado, puede comprobar el estatus de su solicitud',
            idStr: 'Identificación de la solicitud',
            idStrPlaceholder: 'ID de su solicitud',
            idStrErrorMessage: 'El ID de la solicitud no puede estar vacío',
            dayOfBirth: 'Día de nacimiento',
            dayOfBirthPlaceholder: 'Día',
            dayOfBirthErrorMessage: 'Introduzca el día',
            monthOfBirth: 'Mes de nacimiento',
            monthOfBirthPlaceholder: 'Mes',
            monthOfBirthErrorMessage: 'Introduzca el mes',
            yearOfBirth: 'Año de nacimiento',
            yearOfBirthPlaceholder: 'Año',
            yearOfBirthErrorMessage: 'Introduzca el año',
            surname: 'Apellido(s)',
            surnamePlaceholder: 'Su(s) apellido(s)',
            surnameErrorMessage: 'Los apellidos no pueden estar vacíos',
            buttonCheckStatus: 'Comprobar estado',
            January: 'Enero',
            February: 'Febrero',
            March: 'Marzo',
            April: 'Abril',
            May: 'Mayo',
            June: 'Junio',
            July: 'Julio',
            August: 'Agosto',
            September: 'Septiembre',
            October: 'Octubre',
            November: 'Noviembre',
            December: 'Diciembre',
            not_found: {
                title: 'Aplicación no encontrada',
                description: 'No se han encontrado aplicaciones con estos datos, por favor, compruebe los datos introducidos e inténtelo de nuevo.'
            },
            forbidden: {
                title: 'Límite de solicitudes excedido',
                description: 'Límite de solicitudes excedido, por favor inténtelo de nuevo más tarde.'
            }
        },
        status: {
            title: 'Estado de su solicitud',
            back: 'Volver',
            status: 'Estado:',
            appNo: 'Número de la solicitud',
            appDate: 'Fecha de la solicitud',
            pay: 'Realizar un pago',
            unpaid: {
                title: '<span class="text-orange">Solicitud impagada</span>',
                description: 'Su solicitud está pendiente de pago y no podrá tramitarse hasta que recibamos un pago por su parte. Utilice el siguiente enlace para realizar el pago: { link }.'
            },
            'status-0': {
                title: '<span class="text-blue-title">En cola para procesamiento</span>',
                description: 'La solicitud está siendo revisada por nuestro especialista y en la cola para ser procesada. Si necesitamos información adicional, nos pondremos en contacto con usted.'
            },
            'status-1': {
                title: '<span class="text-blue-title">Estamos tramitando su solicitud</span>',
                description : 'Su solicitud está siendo tramitada por las autoridades de visados e inmigración. Tan pronto como se procese, se le enviará una decisión por correo electrónico. No es necesario que haga nada más.'
            },
            'status-2': {
                title: '<span class="text-green">Se aprueba su solicitud</span>',
                description: 'Su solicitud ha sido aprobada con éxito y se le ha enviado la confirmación por correo electrónico. Si no encuentra su confirmación, asegúrese de revisar su carpeta de SPAM.'
            },
            'status-3': {
                title: '<span class="text-green">Su reembolso está en camino</span>',
                description: 'Su reembolso ha sido aprobado y está siendo procesado por nuestro equipo de reembolsos. Dependiendo de su banco, el proceso de reembolso puede tardar hasta 5 días laborables. Recibirás un correo electrónico cuando se envíe el reembolso.'
            },
            'status-4': {
                title: '<span class="text-green">Su reembolso está completo</span>',
                description: 'El reembolso se ha procesado correctamente y debería volver a su cuenta bancaria en los próximos días. También le hemos enviado una confirmación de reembolso por correo electrónico.'
            },
            'status-5': {
                title: '<span class="text-orange">Su solicitud está incompleta</span>',
                description: 'Antes de que podamos empezar a tramitar su solicitud, necesitamos que nos facilite toda la información requerida y los documentos justificativos. Para finalizar la solicitud, inicie sesión en su cuenta evisa.express en {link}. Si no tiene una cuenta evisa.express, regístrese utilizando la misma dirección de correo electrónico que ha utilizado durante el proceso de solicitud y complete su solicitud después de iniciar sesión.'
            },
            'status-7': {
                title: '<span class="text-blue-title">Su solicitud está en espera</span>',
                description: 'Dado que su solicitud debe tramitarse en una fecha más próxima a la fecha de su llegada, de acuerdo con la normativa gubernamental, hemos puesto temporalmente su solicitud en espera. <br /><br />No se preocupe: reanudaremos la tramitación de su solicitud a tiempo adecuado para asegurarnos de que esté lista para cuando empiece su viaje. El proceso es automático y no se requiere ninguna otra acción por su parte. También recibirá un correo electrónico con la decisión una vez procesada su solicitud.'
            },
            'status-8': {
                title: '<span class="text-orange">Documentos que faltan</span>',
                description: 'Necesitamos los documentos justificativos antes de empezar a tramitar su solicitud. Por favor, cargue sus documentos justificativos a través de nuestro portal seguro siguiendo este enlace: {link}.'
            },
            'status-9': {
                title: '<span class="text-red">Cancelado</span>',
                description: 'Su solicitud ha sido cancelada y todos sus datos han sido eliminados de nuestras bases de datos.'
            },
            'status-10': {
                title: '<span class="text-red">Solicitud denegada</span>',
                description: 'Su solicitud ha sido rechazada por las autoridades de visados e inmigración, lo que está fuera de nuestro control. Para más información, póngase en contacto con la embajada más cercana.'
            },
            test_mode: {
                title: '<span class="text-blue-title">Marcada como aplicación de prueba: (status: {status})</span>',
                description: "Su solicitud se ha marcado como 'solicitud de prueba' y no se tramitará. Si es incorrecto, póngase en contacto con <a class='status-link status-link-burgund' href='mailto:{supportEmail}'>{supportEmail}.</a>"
            },
            underpayment: {
                title: '<span class="text-orange">Se requiere un pago adicional</span>',
                description: 'Su solicitud {appId} requiere un pago adicional antes de poder ser procesada. Utilice el siguiente botón para realizar el pago.'
            },
            linkPayment: 'enlace de pago'
        }
    },
    pl: {
        system: {
            backToEvisaExpress: 'Powrót do evisa.express',
            copyrights: 'Evisa Express. Wszelkie prawa zastrzeżone. Evisa Express® jest zastrzeżonym znakiem towarowym.',
            contactUs: 'Kontakt'
        },
        languageSwitcher: {
            en: 'English',
            de: 'Deutsch',
            es: 'Español',
            pl: 'Polski',
            cn: '中文',
            fr: 'Français',
            title: 'Ustawienia języka',
            selectLabel: 'Język',
            save: 'Zapisz i zastosuj',
            cancel: 'Anuluj'
        },
        form: {
            title: 'Sprawdź status swojej wizy',
            description: 'Jeśli aplikowałeś o wizę, sprawdź status swojego wniosku',
            idStr: 'ID wniosku',
            idStrPlaceholder: 'Identyfikator Twojego wniosku',
            idStrErrorMessage: 'Identyfikator wniosku nie może być pusty',
            dayOfBirth: 'Dzień urodzenia',
            dayOfBirthPlaceholder: 'Dzień',
            dayOfBirthErrorMessage: 'Wprowadź dzień',
            monthOfBirth: 'Miesiąc urodzenia',
            monthOfBirthPlaceholder: 'Miesiąc',
            monthOfBirthErrorMessage: 'Wprowadź miesiąc',
            yearOfBirth: 'Rok urodzenia',
            yearOfBirthPlaceholder: 'Rok',
            yearOfBirthErrorMessage: 'Wprowadź rok',
            surname: 'Nazwisko(-a)',
            surnamePlaceholder: 'Twoje nazwisko(-a)',
            surnameErrorMessage: 'Nazwisko(-a) nie może być puste',
            buttonCheckStatus: 'Sprawdź status',
            January: 'Styczeń',
            February: 'Luty',
            March: 'Marzec',
            April: 'Kwiecień',
            May: 'Maj',
            June: 'Czerwiec',
            July: 'Lipiec',
            August: 'Sierpień',
            September: 'Wrzesień',
            October: 'Październik',
            November: 'Listopad',
            December: 'Grudzień',
            not_found: {
                title: 'Nie znaleziono wniosku',
                description: 'Nie znaleziono aplikacji z tymi danymi, sprawdź wprowadzone dane i spróbuj ponownie.'
            },
            forbidden: {
                title: 'Przekroczono limit żądań',
                description: 'Przekroczono limit żądań, proszę spróbować ponownie później.'
            }
        },
        status: {
            title: 'Status Twojej aplikacji',
            back: 'Powrót',
            status: 'Status:',
            appNo: 'Numer wniosku',
            appDate: 'Data złożenia wniosku',
            pay: 'Dokonaj płatności',
            unpaid: {
                title: '<span class="text-orange">Wniosek nieopłacony</span>',
                description: 'Twoja aplikacja jest nieopłacona i nie może zostać przetworzona, dopóki nie otrzymamy od Ciebie płatności. Aby dokonać płatności, skorzystaj z poniższego linka: { link }.'
            },
            'status-0': {
                title: '<span class="text-blue-title">W kolejce do przetworzenia</span>',
                description: 'Aplikacja jest po opieką naszego specjalisty i znajduje się w kolejce do przetworzenia. Jeśli będziemy potrzebować dodatkowych informacji, skontaktujemy się z Tobą.'
            },
            'status-1': {
                title: '<span class="text-blue-title">Przetwarzamy Twoją aplikację</span>',
                description : 'Twój wniosek jest obecnie rozpatrywany przez urząd ds. wiz i imigracji. Po jego rozpatrzeniu decyzja zostanie przesłana pocztą elektroniczną. Dalsze działania nie są wymagane.'
            },
            'status-2': {
                title: '<span class="text-green">Aplikacja została zatwierdzona</span>',
                description: 'Twoja aplikacja została pomyślnie przetworzona, a potwierdzenie zostało wysłane do Ciebie e-mailem. Jeśli nie możesz znaleźć potwierdzenia, sprawdź folder SPAM.'
            },
            'status-3': {
                title: '<span class="text-green">Zwrot pieniędzy jest już w drodze</span>',
                description: 'Zwrot środków został zatwierdzony i jest przetwarzany przez nasz zespół ds. zwrotów. W zależności od banku proces zwrotu może potrwać do 5 dni roboczych. Po wysłaniu zwrotu otrzymasz wiadomość e-mail.'
            },
            'status-4': {
                title: '<span class="text-green">Zwrot kosztów został zakończony</span>',
                description: 'Zwrot został pomyślnie przetworzony i powinien znaleźć się na Twoim koncie bankowym w ciągu kilku najbliższych dni. Wysłaliśmy również potwierdzenie zwrotu pieniędzy pocztą elektroniczną.'
            },
            'status-5': {
                title: '<span class="text-orange">Aplikacja jest niekompletna</span>',
                description: 'Zanim będziemy mogli rozpocząć przetwarzanie wniosku, musisz podać wszystkie wymagane informacje i dokumenty uzupełniające. Aby dokończyć składanie wniosku, zaloguj się na swoje konto evisa.express pod adresem {link}. Jeśli nie masz konta evisa.express, zarejestruj się przy użyciu tego samego adresu e-mail, którego użyłeś podczas procesu składania wniosku i wypełnij wniosek po zalogowaniu.'
            },
            'status-7': {
                title: '<span class="text-blue-title">Aplikacja została wstrzymana</span>',
                description: 'Zgodnie z przepisami rządowymi Twój wniosek musi zostać rozpatrzony bliżej daty przyjazdu. Z tego względu, tymczasowo wstrzymaliśmy jego przetwarzanie. <br /><br />Nie martw się - wznowimy przetwarzanie Twojej aplikacji w odpowiednim czasie, aby upewnić się, że będzie ona gotowa w momencie rozpoczęcia przez Ciebie podróży. Proces jest automatyczny i nie są wymagane żadne dalsze działania z Twojej strony. Po rozpatrzeniu wniosku otrzymasz wiadomość e-mail z decyzją.'
            },
            'status-8': {
                title: '<span class="text-orange">Brakujące dokumenty</span>',
                description: 'Do poprawnego przetworzenia wniosku potrzebujemy od Ciebie dokumentów uzupełniających. Prosimy o przesłanie dokumentów za pomocą naszego bezpiecznego portalu, korzystając z tego linku: {link}.'
            },
            'status-9': {
                title: '<span class="text-red">Anulowany</span>',
                description: 'Twoja aplikacja została anulowana, a wszystkie Twoje dane zostały usunięte z naszych baz danych.'
            },
            'status-10': {
                title: '<span class="text-red">Wniosek odrzucony</span>',
                description: 'Twój wniosek został odrzucony przez władze wizowe i imigracyjne, na co nie mamy wpływu. Aby uzyskać więcej informacji, skontaktuj się z najbliższą ambasadą.'
            },
            test_mode: {
                title: '<span class="text-blue-title">Oznaczony jako aplikacja testowa: (status: {status})</span>',
                description: "Twoje zgłoszenie zostało oznaczone jako 'zgłoszenie testowe' i nie zostanie rozpatrzone. Jeśli jest to nieprawidłowe, prosimy o kontakt <a class='status-link status-link-burgund' href='mailto:{supportEmail}'>{supportEmail}.</a>"
            },
            underpayment: {
                title: '<span class="text-orange">Wymagana jest dodatkowa płatność</span>',
                description: 'Twoja aplikacja {appId} wymaga dodatkowej płatności, zanim będzie mogła zostać przetworzona. Użyj poniższego przycisku, aby dokonać płatności.'
            },
            linkPayment: 'link do płatności'
        }
    },
    cn: {
        system: {
            backToEvisaExpress: '返回evisa.express',
            copyrights: 'Evisa Express。保留所有权利。Evisa Express® 是注册商标。',
            contactUs: '联系我们'
        },
        languageSwitcher: {
            en: 'English',
            de: 'Deutsch',
            es: 'Español',
            pl: 'Polski',
            cn: '中文',
            fr: 'Français',
            title: '语言设置',
            selectLabel: '语言',
            save: '保存并应用',
            cancel: '取消'
        },
        form: {
            title: '检查您的签证状态',
            description: '如果您已申请签证，您可以查看申请状态',
            idStr: '应用 ID',
            idStrPlaceholder: '您的申请 ID',
            idStrErrorMessage: '申请 ID 不能为空',
            dayOfBirth: '出生日期',
            dayOfBirthPlaceholder: '日',
            dayOfBirthErrorMessage: '输入日期',
            monthOfBirth: '出生年月',
            monthOfBirthPlaceholder: '月份',
            monthOfBirthErrorMessage: '输入月份',
            yearOfBirth: '出生年份',
            yearOfBirthPlaceholder: '年份',
            yearOfBirthErrorMessage: '输入年份',
            surname: '姓氏',
            surnamePlaceholder: '您的姓氏',
            surnameErrorMessage: '姓氏不能为空',
            buttonCheckStatus: '检查状态',
            January: '一月份',
            February: '二月份',
            March: '三月份',
            April: '四月',
            May: '五月份',
            June: '六月',
            July: '七月',
            August: '八月',
            September: '九月份',
            October: '十月份',
            November: '十一月',
            December: '十二月',
            not_found: {
                title: '未找到应用程序',
                description: '未找到包含这些信息的应用程序，请仔细检查输入的数据并重试。'
            },
            forbidden: {
                title: '请求限制已超出',
                description: '请求限制已超出，请稍后再试。'
            }
        },
        status: {
            title: '您的申请状态',
            back: '返回',
            status: '状态：',
            appNo: '申请编号',
            appDate: '申请日期',
            pay: '付款',
            unpaid: {
                title: '<span class="text-orange">未付申请费</span>',
                description: '您的申请尚未付款，在收到您的付款之前，我们无法处理您的申请。请使用以下链接完成付款：{ link }.',
            },
            'status-0': {
                title: '<span class="text-blue-title">等待处理</span>',
                description: '我们的专家正在审查该申请，并在排队等待处理。如果我们需要您提供任何补充信息，我们将与您联系。'
            },
            'status-1': {
                title: '<span class="text-blue-title">我们正在处理您的申请</span>',
                description : '签证和移民局正在处理您的申请。一旦处理完毕，将通过电子邮件向您发送决定。您无需采取进一步行动。'
            },
            'status-2': {
                title: '<span class="text-green">您的申请已获批准</span>',
                description: '您的申请已成功通过审批，确认函已通过电子邮件发送给您。如果您找不到确认函，请检查您的垃圾邮件文件夹。'
            },
            'status-3': {
                title: '<span class="text-green">您的退款即将到账</span>',
                description: '我们的退款团队已批准并正在处理您的退款。根据银行的不同，退款过程可能需要 5 个工作日。退款发出后，您将收到一封电子邮件。'
            },
            'status-4': {
                title: '<span class="text-green">您的退款已完成</span>',
                description: '您的退款已成功处理，并将在几天内返回您的银行账户。我们还通过电子邮件向您发送了退款确认函。'
            },
            'status-5': {
                title: '<span class="text-orange">您的申请不完整</span>',
                description: '在开始处理您的申请之前，我们需要您提供所有必要的信息和证明文件。为了完成申请，请登录您在{link}的evisa.express帐户。如果您没有 evisa.express 帐户，请使用您在申请过程中使用的同一电子邮件地址进行注册，并在登录后完成申请。'
            },
            'status-7': {
                title: '<span class="text-blue-title">您的申请被搁置</span>',
                description: '根据政府规定，您的申请需要在您抵达日期前处理，因此我们暂时搁置了您的申请。<br /><br />别担心，我们会及时恢复处理您的申请，以确保在您启程时为您准备好申请材料。该过程是自动进行的，您无需采取进一步行动。申请处理完毕后，您还将收到一封电子邮件，告知处理决定。'
            },
            'status-8': {
                title: '<span class="text-orange">缺失的文件</span>',
                description: '在开始处理您的申请之前，我们需要您提供证明文件。请通过以下链接使用我们的安全门户网站上传您的证明文件： {link}。'
            },
            'status-9': {
                title: '<span class="text-red">已取消</span>',
                description: '您的申请已被取消，您的所有数据已从我们的数据库中删除。'
            },
            'status-10': {
                title: '<span class="text-red">申请被拒</span>',
                description: '您的申请已被签证和移民局拒绝，这超出了我们的控制范围。请联系离您最近的大使馆了解详情。'
            },
            test_mode: {
                title: '<span class="text-blue-title">标记为测试应用程序: (status: {status})</span>',
                description: "您的申请已被标记为 '测试申请'，将不予处理。如果不正确，请联系 <a class='status-link status-link-burgund' href='mailto:{supportEmail}'>{supportEmail}.</a>"
            },
            underpayment: {
                title: '<span class="text-orange">需要额外付款</span>',
                description: '您的应用程序 {appId} 需要额外付款才能处理。请使用下面的按钮付款。'
            },
            linkPayment: '付款链接'
        }
    },
    fr: {
        system: {
            backToEvisaExpress: 'Retour à evisa.express',
            copyrights: 'Évisa Express. Tous droits réservés. L\'Evisa Express® est une marque déposée.',
            contactUs: 'Contactez nous'
        },
        '404': {
            banner : {
                title: '404',
                subtitle: 'Page non trouvée',
            },
            content: 'La page demandée n\'a pas été trouvée sur ce serveur.',
        },
        languageSwitcher: {
            en: 'English',
            de: 'Deutsch',
            es: 'Español',
            pl: 'Polski',
            cn: '中文',
            fr: 'Français',
            title: 'Paramètres de langue',
            selectLabel: 'Langue',
            save: 'Enregistrer et postuler',
            cancel: 'Annuler'
        },
        form: {
            title: 'Vérifiez le statut de votre visa',
            description: 'Si vous avez demandé un visa, vous pouvez vérifier le statut de la demande',
            idStr: 'ID d\'application',
            idStrPlaceholder: 'Votre identifiant de candidature',
            idStrErrorMessage: 'L\'ID de l\'application ne peut pas être vide',
            dayOfBirth: 'Date de naissance',
            dayOfBirthPlaceholder: 'Jour',
            dayOfBirthErrorMessage: 'Entrez le jour',
            monthOfBirth: 'Le mois de naissance',
            monthOfBirthPlaceholder: 'Mois',
            monthOfBirthErrorMessage: 'Entrez le mois',
            yearOfBirth: 'Année de naissance',
            yearOfBirthPlaceholder: 'Année',
            yearOfBirthErrorMessage: 'Entrez l\'année',
            surname: 'Nom(s)',
            surnamePlaceholder: 'Votre (vos) nom(s)',
            surnameErrorMessage: 'Les noms de famille ne peuvent pas être vides',
            buttonCheckStatus: 'Vérifier l\'état',
            January: 'Janvier',
            February: 'Février',
            March: 'Mars',
            April: 'Avril',
            May: 'Peut',
            June: 'Juin',
            July: 'Juillet',
            August: 'Août',
            September: 'Septembre',
            October: 'Octobre',
            November: 'Novembre',
            December: 'December',
            not_found: {
                title: 'Application non trouvée',
                description: 'Aucune application trouvée avec ces informations, veuillez vérifier les données saisies et réessayer.'
            },
            forbidden: {
                title: 'Limite de requêtes dépassée',
                description: 'Limite de demandes dépassée, veuillez réessayer plus tard.'
            },
        },
        status: {
            title: 'Statut de votre candidature',
            back: 'Dos',
            status: 'Statut:',
            appNo: 'Numéro d\'application',
            appDate: 'Date d\'application',
            pay: 'Make a payment',
            unpaid: {
                title: '<span class="text-orange">Demande impayée</span>',
                description: 'Votre demande n\'est pas payée et ne peut être traitée que lorsque nous recevons un paiement de votre part. Veuillez utiliser le lien suivant pour effectuer un paiement: {link}'
            },
            'status-0': {
                title: '<span class="text-blue-title">En file d\'attente pour le traitement</span>',
                description: 'La demande est en cours d\'examen par notre spécialiste et est en attente de traitement. Si nous avons besoin d’informations supplémentaires de votre part, nous vous contacterons.'
            },
            'status-1': {
                title: '<span class="text-blue-title">Nous traitons votre candidature</span>',
                description : 'Votre demande est en cours de traitement par les autorités des visas et de l\'immigration. Dès qu\'elle sera traitée, une décision vous sera envoyée par e-mail. Aucune autre action n’est requise de votre part.'
            },
            'status-2': {
                title: '<span class="text-green">Votre candidature est approuvée</span>',
                description: 'Votre candidature a été approuvée avec succès et la confirmation vous a été envoyée par e-mail. Si vous ne trouvez pas votre confirmation, assurez-vous de vérifier votre dossier SPAM.'
            },
            'status-3': {
                title: '<span class="text-green">Votre remboursement est en route</span>',
                description: 'Votre remboursement a été approuvé et est en cours de traitement par notre équipe de remboursement. Selon votre banque, le processus de remboursement peut prendre jusqu\'à 5 jours ouvrables. Vous recevrez un e-mail une fois le remboursement envoyé.'
            },
            'status-4': {
                title: '<span class="text-green">Votre remboursement est terminé</span>',
                description: 'Votre remboursement a été traité avec succès et devrait être de retour sur votre compte bancaire dans les prochains jours. Nous vous avons également envoyé une confirmation de remboursement par e-mail.'
            },
            'status-5': {
                title: '<span class="text-orange">Votre candidature est incomplète</span>',
                description: 'Avant de pouvoir commencer à traiter votre candidature, nous avons besoin que vous fournissiez toutes les informations et pièces justificatives requises. Afin de finaliser la candidature, veuillez vous connecter à votre compte evisa.express sur {link}. Si vous n\'avez pas de compte evisa.express, veuillez vous inscrire en utilisant la même adresse e-mail que celle que vous avez utilisée lors du processus de candidature et compléter votre candidature après vous être connecté.'
            },
            'status-7': {
                title: '<span class="text-blue-title">Votre candidature est en attente</span>',
                description: 'Étant donné que votre demande doit être traitée à l\'approche de votre date d\'arrivée conformément aux réglementations gouvernementales, nous avons temporairement suspendu votre demande. Ne vous inquiétez pas, nous reprendrons le traitement de votre demande dans les meilleurs délais pour garantir qu\'elle soit prête pour vous au début de votre voyage. <br /><br />Le processus est automatique et aucune autre action n\'est requise de votre part. Vous recevrez également un e-mail avec la décision une fois votre demande traitée.'
            },
            'status-8': {
                title: '<span class="text-orange">Documents manquants</span>',
                description: 'Nous avons besoin de pièces justificatives avant de pouvoir commencer à traiter votre demande. Veuillez télécharger vos pièces justificatives en utilisant notre portail sécurisé en suivant ce lien: {link}.'
            },
            'status-9': {
                title: '<span class="text-red">Annulé</span>',
                description: 'Votre candidature a été annulée et toutes vos données ont été supprimées de nos bases de données.'
            },
            'status-10': {
                title: '<span class="text-red">Application refused</span>',
                description: 'Your application has been refused by the visas & immigration authorities, which is outside of our control. Please contact your nearest embassy for more details.'
            },
            test_mode: {
                title: '<span class="text-blue-title">Marqué comme application de test: (statut: {status})</span>',
                description: "Votre candidature a été marquée comme \'candidature test\' et ne sera pas traitée. Si cela est incorrect, veuillez contacter <a class='status-link status-link-burgund' href='mailto:{supportEmail}'>{supportEmail}.</a>"
            },
            underpayment: {
                title: '<span class="text-orange">Un paiement supplémentaire est requis</span>',
                description: 'Votre demande {appId} nécessite un paiement supplémentaire avant de pouvoir être traitée. Utilisez le bouton ci-dessous pour effectuer un paiement.'
            },
            linkPayment: 'lien de paiement'
        }
    }
}

export default messages;