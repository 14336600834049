import { defineStore } from "pinia";
import { ref } from "vue";

export const useCaptchaStore = defineStore('captcha', () => {
    const token = ref(null);
    const recaptchaNode = ref(null);

    async function addCaptcha() {
        grecaptcha.enterprise.ready(() => {
            grecaptcha.enterprise.render('recaptcha', {
                'sitekey': process.env.VITE_RECAPTCHA_SITEKEY,
                'callback': captchaCallback,
                'expired-callback': expiredCallback
            });
        });
    }

    async function captchaCallback(responseToken) {
        token.value = responseToken;
        setCaptchaValid(true);
    }

    function expiredCallback(){
        token.value = null;
    }

    function resetCaptcha() {
        grecaptcha.enterprise.reset();
        expiredCallback();
        setCaptchaValid(true);
    }

    function setCaptchaValid(isValid) {
        isValid ? recaptchaNode.value.classList.remove("invalid") : recaptchaNode.value.classList.add("invalid");
    }

    return {
        token,
        addCaptcha,
        recaptchaNode,
        captchaCallback,
        expiredCallback,
        resetCaptcha,
        setCaptchaValid
    }
});