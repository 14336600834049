<template>
    <section class="checkstatus">
        <div class="top-handler">
            <div class="top-bg-handler">
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-9">
                    <section class="privacy-policy-card">
                        <div class="contact-form-h1">
                            {{ $t('404.banner.title') }}
                        </div>
                        <div class="text-black-22px">
                            {{ $t('404.banner.subtitle') }}
                        </div>
                    </section>
                    <section class="contact-form padding">
                        <div class="text-black-22px">
                            <p>{{ $t('404.content') }}</p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        </section>
</template>

<script setup></script>
