<template>
    <section class="check-status">
        <div class="paper">
            <component
                :is="store.steps[store.activeStep]"
            />
        </div>
    </section>
</template>

<script setup>
import { useGeneralStore } from '../store/general.js';

const store = useGeneralStore();
</script>