<template>
    <div class="status-wrapper">
        <div class="status-btn-wrapper">
            <button class="btn btn-white btn-back" @click="store.previousStep">
                <img src="/assets/icon/arrow-left-icon.svg" alt="Back">
                <span>{{ $t('status.back') }}</span>
            </button> 
        </div>
        <h1 class="status-title">{{ $t('status.title') }}</h1>
        <div class="status-separator"></div>
        <div class="status">
            <div class="status-left-side">
                <div class="status-info-wrapper">
                  <span>{{ $t('status.status') }}</span>&nbsp;
                  <span v-if="store.isTestMode" v-html="$t('status.test_mode.title', { status: store.formStatus })"></span>
                  <span v-else v-html="$t('status.' + store.formStatus + '.title')"></span>
                </div>
                <div class="status-description-wrapper">
                    <p class="status-description" v-html="statusDescription"></p>
                    <button v-if="store.formStatus === 'underpayment'" class="btn btn-burgund make-payment" @click="redirectToSp">
                        <span>{{ $t('status.pay') }}</span>
                        <img src="assets/icon/arrow-right-icon.svg">
                    </button>
                </div>
            </div>
            <div class="status-right-side">
                <div class="status-form-info-wrapper">
                    <span class="status-info-title">{{ $t('status.appNo') }}</span>
                    <span class="status-info-value">{{ store.fields['idStr'].value }}</span>
                </div>
                <div class="status-form-info-wrapper">
                    <span class="status-info-title">{{ $t('status.appDate') }}</span>
                    <span class="status-info-value">{{ store.appDate }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useGeneralStore } from '../../store/general';
import { useI18n } from 'vue-i18n';

const store = useGeneralStore();
const i18n = useI18n();

const paymentLink = computed(() => {
    return `<a class="status-link" href="https://secure-payment.evisa.express?hash=${store.orderHash}" target="_blank">https://secure-payment.evisa.express?hash=${store.orderHash}</a>`;
});

const missingDocumentsLink = computed(() => {
    return `<a class="status-link" href="https://evisa.express/${i18n.locale.value}/upload-documents?hash=${store.orderHash}" target="_blank">https://evisa.express/${i18n.locale.value}/upload-documents?hash=${store.orderHash}</a>`;
});

const accountLink = computed(() => {
    return `<a class="status-link" target="_blank" href="https://evisa.express/${i18n.locale.value}/account/login">https://evisa.express/${i18n.locale.value}/account/login</a>`
});

const statusDescription = computed(() => {
  if (store.isTestMode) {
    return i18n.t('status.test_mode.description', {status: store.formStatus})
  }
  switch(store.formStatus) {
    case 'unpaid':
      return i18n.t('status.' + store.formStatus + '.description', { link: paymentLink.value });
    case 'status-5':
      return i18n.t('status.' + store.formStatus + '.description', { link: accountLink.value });
    case 'status-8':
      return i18n.t('status.' + store.formStatus + '.description', { link: missingDocumentsLink.value });
    case 'underpayment':
      return i18n.t('status.' + store.formStatus + '.description', { appId: store.fields['idStr'].value });
   default:
      return i18n.t('status.' + store.formStatus + '.description');
  }
});

function redirectToSp() {
    window.open('https://secure-payment.evisa.express/surcharge?hash=' + store.surchargeHash, '_blank');
}
</script>

