import '../scss/main.scss';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createI18n } from 'vue-i18n';
import { createRouter, createWebHistory } from 'vue-router';
import CheckStatus from './components/CheckStatus.vue';
import PageNotFound from './components/404.vue';
import messages from './i18n/translations.js';
import App from "./App.vue";
import axios from "axios";

const pinia = createPinia();

const languages = ['en', 'de', 'es', 'pl', 'cn', 'fr'];
const lang = window.location.pathname.split('/')[1] === '' ? 'en' : window.location.pathname.split('/')[1];

const routes = [
    { path: '/', component: CheckStatus},
    { path: '/:lang(\[a-z]{2})', component: CheckStatus},
    { path: '/404', component: PageNotFound },
    { path: '/:pathMatch(.*)*', component: PageNotFound },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const i18n = createI18n({
    legacy: false,
    locale: lang,
    fallbackLocale: 'en',
    messages
});

router.beforeEach((to, from) => {
    if(to.params.lang && !languages.includes(to.params.lang)) {
        return { path: '/404' };
    }

    if(to.params.lang && languages.includes(to.params.lang)) {
        i18n.global.locale.value = to.params.lang;
    } else {
        i18n.global.locale.value = 'en';
    }
});

axios.interceptors.request.use(function (request) {
    request.headers['Accept'] = 'application/json';

    if (request.headers["Content-Type"] === undefined) {
        request.headers["Content-Type"] = "application/json";
    }

    if (process.env.NODE_ENV === 'production') {
        request.url = request.url.replace('/api', `/${process.env.VITE_DESTINATION_HASH}/api`);
    } else {
        request.headers.Authorization = "Bearer " + process.env.VITE_TOKEN;
    }

    return request;
});


axios.interceptors.response.use(
  function (response) {
      return response;
  },
  function (error) {
      return Promise.reject(error);
  });

const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(i18n);
app.provide('languages', languages);

window.addEventListener('load', () => {
    app.mount('#app');
});