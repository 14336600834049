<template>
    <div class="form-row">
        <label class="form-input-wrapper">
            <span class="form-input-label">{{ $t(data.label) }}</span>
            <input 
                class="form-input" 
                :class="validationClass"
                type="text" 
                :name="data.name" 
                :placeholder="data.placeholder ? $t(data.placeholder) : ''" 
                :value="value"
                @input="handleInput"
                @focusout="handleFocusout"
            />
            <span class="form-error-message" v-if="touched && !valid">{{ data.errorMessage ? $t(data.errorMessage) : '' }}</span>
        </label>
    </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';

const props = defineProps({ 
    data: {
        type: Object,
        required: true
    },
    value: {
        type: String,
        default: ''
    },
    valid: {
        type: Boolean,
        default: false
    },
    touched: {
        type: Boolean,
        default: false
    },
});

const emit = defineEmits(['update:value', 'update:valid', 'update:touched']);

const validationClass = computed(() => {
    if (props.touched && !props.valid) {
        return '--invalid';
    } else {
        return '';
    }
});

function handleInput(event) {
    const query = event.target.value;

    if (!props.touched) {
        emit('update:touched', true);
    } 

    let valid = validateField(query);

    emit('update:value', query);
    emit('update:valid', valid);
}

function handleFocusout() {
    let valid = validateField(props.value);

    emit('update:valid', valid);

    if (!props.touched) {
        emit('update:touched', true);
    }
}

function validateField(value) {
    return value.length > 0;
}

onMounted(() => {
    if (props.value !== '') {
        emit('update:touched', true);
        validateField(props.value);
    }
});
</script>