<template>
  <div class="form-row">
    <SelectInput
      :data="fieldDay"
      v-model:value="date.day.value"
      v-model:touched="date.day.touched"
      v-model:valid="date.day.valid"
    />
    <SelectInput
      :data="fieldMonth"
      v-model:value="date.month.value"
      v-model:touched="date.month.touched"
      v-model:valid="date.month.valid"
    />
    <SelectInput
      :data="fieldYear"
      v-model:value="date.year.value"
      v-model:touched="date.year.touched"
      v-model:valid="date.year.valid"
    />
  </div>
</template>

<script setup>
import { computed, nextTick, ref, shallowRef, toRefs, watch } from 'vue';
import { TypeEnum, DirectionEnum } from '../../../utils/enums';
import SelectInput from './SelectInput.vue';
import { useGeneralStore } from '../../../store/general';
import { dayCodes, monthCodes, yearCodes } from '../../../utils/dateCodes';

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  value: {
    type: Object,
    default: {
      day: 0,
      month: 0,
      year: 0
    }
  },
  valid: {
    type: Boolean,
    default: false
  },
  touched: {
    type: Boolean,
    default: false
  },
});

const store = useGeneralStore();
const { value, data, touched } = toRefs(props);
const emit = defineEmits(['update:value', 'update:valid', 'update:touched']);

const date = ref({
  day: { value: 0, valid: false, touched: false },
  month: { value: 0, valid: false, touched: false },
  year: { value: 0, valid: false, touched: false }
});

const minDate = new Date().setFullYear(1920);
const maxDate = new Date();

const getStringFromValue = value => String(value).padStart(2, '0');

const convertDateValue = (input, type, direction) => {
  let codesArray;
  switch (type) {
    case TypeEnum.DAY:
      codesArray = dayCodes;
      break;
    case TypeEnum.MONTH:
      codesArray = monthCodes;
      break;
    case TypeEnum.YEAR:
      codesArray = yearCodes;
      break;
    default:
      return null;
  }
  if (direction === DirectionEnum.TO_CODE) {
    const codeObj = codesArray.find(item => item[type] === input);
    return codeObj ? codeObj.code : null;
  } else if (direction === DirectionEnum.TO_VALUE) {
    const valueObj = codesArray.find(item => item.code === input);
    return valueObj ? valueObj[type] : null;
  }
  return null;
}

const realDate = computed(() => ({
  day: convertDateValue(date.value.day.value, TypeEnum.DAY, DirectionEnum.TO_VALUE),
  month: convertDateValue(date.value.month.value, TypeEnum.MONTH, DirectionEnum.TO_VALUE),
  year: convertDateValue(date.value.year.value, TypeEnum.YEAR, DirectionEnum.TO_VALUE)
}));

const daysInMonth = computed(() => {
  return date.value.month.value && date.value.year.value ? new Date(realDate.value.year, realDate.value.month, 0).getDate() : 31;
})

const dayInMonthExists = computed(() => options.value.day.find((day) => day.value === date.value.day.value) ? true : false);

const options = computed(() => {
  const minYear = new Date(minDate).getFullYear();
  const maxYear = new Date(maxDate).getFullYear();
  const yearsDiff = maxYear > minYear ? maxYear - minYear : 1;
  const days = shallowRef(
    Array.from(
      { length: daysInMonth.value },
      (_, i) => ({
        label: getStringFromValue(i + 1),
        value: convertDateValue(i + 1, TypeEnum.DAY, DirectionEnum.TO_CODE)
      })
    )
  );
  const months = shallowRef(
    Array.from(
      { length: 12 },
      (_, i) => ({
        label: store.getTranslationLabel(i),
        value: convertDateValue(i + 1, TypeEnum.MONTH, DirectionEnum.TO_CODE)
      })
    )
  );
  const years = shallowRef(
    Array.from(
      { length: yearsDiff + 1 },
      (_, i) => ({
        label: i + minYear,
        value: convertDateValue(i + minYear, TypeEnum.YEAR, DirectionEnum.TO_CODE)
      })
    )
  );
  return { day: days.value, month: months.value, year: years.value }
});

const fieldDay = computed(() => ({
  label: data.value.label.day,
  placeholder: data.value.placeholder.day,
  options: options.value.day,
  errorMessage: data.value.errorMessage.day
}));

const fieldMonth = computed(() => ({
  label: data.value.label.month,
  placeholder: data.value.placeholder.month,
  options: options.value.month,
  errorMessage: data.value.errorMessage.month
}));

const fieldYear = computed(() => ({
  label: data.value.label.year,
  placeholder: data.value.placeholder.year,
  options: options.value.year.reverse(),
  errorMessage: data.value.errorMessage.year
}));

const verifyAndInsertValue = () => {
  if (date.value.day.valid && date.value.month.valid && date.value.year.valid) {
    const birthDate = {
      day: date.value.day.value,
      month: date.value.month.value,
      year: date.value.year.value
    }
    const selectedDate = new Date(realDate.value.year, realDate.value.month, realDate.value.day);
    if (selectedDate < minDate || selectedDate > maxDate || date.value.day > daysInMonth.value) {
      date.value.day.valid = false;
    } else {
      date.value.day.valid = true;
      nextTick(() => {
        emit('update:valid', true);
        emit('update:value', birthDate);
      });
    }
  }
}

watch(date, (value) => {
  if (value.day.value && value.month.value && value.year.value) {
    emit('update:touched', true);
    verifyAndInsertValue();
  }
}, {deep: true});

watch(() => touched.value, (touched) => {
  if (touched) {
    date.value.day.touched = true;
    date.value.month.touched = true;
    date.value.year.touched = true;
  }
});

</script>
