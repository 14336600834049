import axios from 'axios';

const REQUEST_REFETCH_LIMIT = 1;

export default async function axiosWrapper(options, retry = true) {
  let retries = 0,
    fulfilled = false,
    response;

  do {
    try {
      response = await axios(options);
      fulfilled = true;
    } catch (error) {
      response = error.response;
    }

    retries++;
  } while (retry && retries < REQUEST_REFETCH_LIMIT && !fulfilled);

  return response;
}
