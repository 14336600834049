<template>
    <div class="form-wrapper">
        <h1 class="form-title">{{ $t('form.title') }}</h1>
        <h2 class="form-description">{{ $t('form.description') }}</h2>
        <div class="form">
            <template v-for="field in store.form" :key="field.name">
                <component
                    :is="fieldTypes[field.type]"
                    :data="field"
                    v-model:value="store.fields[field.name].value"
                    v-model:valid="store.fields[field.name].valid"
                    v-model:touched="store.fields[field.name].touched"
                />
            </template>
            <div :ref="(el) => captchaStore.recaptchaNode=el" id="recaptcha" class="g-recaptcha" style="margin: auto;"></div>
            <button class="btn btn-burgund check-status" @click="handleApply">
                <span v-if="!store.isLoading">{{ $t('form.buttonCheckStatus') }}</span>
                <div v-else class="loading-spinner"></div>
            </button>
        </div>
        <div class="result-wrapper not-found-wrapper" v-if="store.formStatus && store.formStatus === formStatus.notFound">
            <img src="/assets/icon/not-found-icon.svg" class="not-found-icon" alt="Not found">
            <div class="not-found-text">
                <h2 class="not-found-title">{{ $t('form.' + store.formStatus + '.title') }}</h2>
                <p class="not-found-description">{{ $t('form.' + store.formStatus + '.description') }}</p>
            </div>
        </div>
        <div class="result-wrapper forbidden-wrapper" v-if="store.formStatus && store.formStatus === formStatus.forbidden">
          <img src="/assets/icon/not-found-icon.svg" class="forbidden-icon" alt="Forbidden">
          <div class="forbidden-text">
            <h2 class="forbidden-title">{{ $t('form.' + store.formStatus + '.title') }}</h2>
            <p class="forbidden-description">{{ $t('form.' + store.formStatus + '.description') }}</p>
          </div>
        </div>
    </div>
</template>

<script setup>
import {useGeneralStore} from '../../store/general';
import TextInput from './fields/TextInput.vue';
import DateSelect from './fields/DateSelect.vue';
import { useCaptchaStore } from '../../store/captcha';
import {nextTick, onMounted, watch} from 'vue';

const store = useGeneralStore();

const captchaStore = useCaptchaStore();

onMounted(() => {
  window.onpopstate = () => resetForm();
  captchaStore.addCaptcha();
});

const fieldTypes = {
    TextInput: TextInput,
    DateSelect: DateSelect
};

const formStatus = {
    notFound: 'not_found',
    forbidden: 'forbidden',
    badRequest: 'bad_request'
};

function touchAndValidateFields() {
    let valid = true;

    store.form.forEach((field) => {
        if (!store.fields[field.name].touched) {
            store.fields[field.name].touched = true;
        }

        if (!store.fields[field.name].valid) {
            valid = false;
        }
    });

    return valid;
}

async function handleApply() {
    if (!touchAndValidateFields()) {
        return;
    }

  const formData = {};
  for (const key in store.fields) {
    if (key !== 'dateOfBirth') {
      formData[key] = store.fields[key].value;
    }
    if (typeof store.fields[key].value === 'object') {
      for (const dateKey in store.fields[key].value) {
        if (dateKey === 'day') {
          formData['birthDay'] = store.fields[key].value[dateKey];
        }
        if (dateKey === 'month') {
          formData['birthMonth'] = store.fields[key].value[dateKey];
        }
        if (dateKey === 'year') {
          formData['birthYear'] = store.fields[key].value[dateKey];
        }
      }
    }
  }
  
  if(!captchaStore.token) {
    captchaStore.setCaptchaValid(false);
    return;
  }

  formData['g-recaptcha-response'] = captchaStore.token;

  captchaStore.resetCaptcha();

  await store.getApplicationData(formData).then((data) => {
        if(data && !Object.values(formStatus).includes(store.formStatus)) {
            store.nextStep();
        }
    });
}

function resetForm() {
  for (const key in store.fields) {
    store.fields[key].value = '';
    store.fields[key].touched = false;
    store.fields[key].valid = false;
  }
}

function scrollToWrapper() {
  const resultWrapper = document.querySelector('.result-wrapper');
  if (resultWrapper) {
    resultWrapper.scrollIntoView({behavior: 'smooth'});
  }
}

watch(() => store.formStatus, (status) => {
  if (status === formStatus.notFound || status === formStatus.forbidden) {
    nextTick(() => scrollToWrapper());
  }
});
</script>