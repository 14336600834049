export const dayCodes = [
  { day: 1, code: 547 },
  { day: 2, code: 548 },
  { day: 3, code: 549 },
  { day: 4, code: 550 },
  { day: 5, code: 551 },
  { day: 6, code: 552 },
  { day: 7, code: 553 },
  { day: 8, code: 554 },
  { day: 9, code: 555 },
  { day: 10, code: 556 },
  { day: 11, code: 557 },
  { day: 12, code: 558 },
  { day: 13, code: 559 },
  { day: 14, code: 560 },
  { day: 15, code: 561 },
  { day: 16, code: 562 },
  { day: 17, code: 563 },
  { day: 18, code: 564 },
  { day: 19, code: 565 },
  { day: 20, code: 566 },
  { day: 21, code: 567 },
  { day: 22, code: 568 },
  { day: 23, code: 569 },
  { day: 24, code: 570 },
  { day: 25, code: 571 },
  { day: 26, code: 572 },
  { day: 27, code: 573 },
  { day: 28, code: 574 },
  { day: 29, code: 575 },
  { day: 30, code: 576 },
  { day: 31, code: 577 }
];

export const monthCodes = [
  { month: 1, code: 1819 },
  { month: 2, code: 1818 },
  { month: 3, code: 1822 },
  { month: 4, code: 1827 },
  { month: 5, code: 1823 },
  { month: 6, code: 1821 },
  { month: 7, code: 1820 },
  { month: 8, code: 1816 },
  { month: 9, code: 1826 },
  { month: 10, code: 1825 },
  { month: 11, code: 1824 },
  { month: 12, code: 1817 }
];

export const yearCodes = [
  { year: 1900, code: 883 },
  { year: 1901, code: 884 },
  { year: 1902, code: 885 },
  { year: 1903, code: 886 },
  { year: 1904, code: 887 },
  { year: 1905, code: 888 },
  { year: 1906, code: 889 },
  { year: 1907, code: 890 },
  { year: 1908, code: 891 },
  { year: 1909, code: 892 },
  { year: 1910, code: 893 },
  { year: 1911, code: 894 },
  { year: 1912, code: 895 },
  { year: 1913, code: 896 },
  { year: 1914, code: 897 },
  { year: 1915, code: 898 },
  { year: 1916, code: 899 },
  { year: 1917, code: 900 },
  { year: 1918, code: 901 },
  { year: 1919, code: 902 },
  { year: 1920, code: 903 },
  { year: 1921, code: 904 },
  { year: 1922, code: 905 },
  { year: 1923, code: 906 },
  { year: 1924, code: 907 },
  { year: 1925, code: 908 },
  { year: 1926, code: 909 },
  { year: 1927, code: 910 },
  { year: 1928, code: 911 },
  { year: 1929, code: 912 },
  { year: 1930, code: 913 },
  { year: 1931, code: 914 },
  { year: 1932, code: 915 },
  { year: 1933, code: 916 },
  { year: 1934, code: 917 },
  { year: 1935, code: 918 },
  { year: 1936, code: 919 },
  { year: 1937, code: 920 },
  { year: 1938, code: 921 },
  { year: 1939, code: 922 },
  { year: 1940, code: 923 },
  { year: 1941, code: 924 },
  { year: 1942, code: 925 },
  { year: 1943, code: 926 },
  { year: 1944, code: 927 },
  { year: 1945, code: 928 },
  { year: 1946, code: 929 },
  { year: 1947, code: 930 },
  { year: 1948, code: 931 },
  { year: 1949, code: 932 },
  { year: 1950, code: 933 },
  { year: 1951, code: 934 },
  { year: 1952, code: 935 },
  { year: 1953, code: 936 },
  { year: 1954, code: 937 },
  { year: 1955, code: 938 },
  { year: 1956, code: 939 },
  { year: 1957, code: 940 },
  { year: 1958, code: 941 },
  { year: 1959, code: 942 },
  { year: 1960, code: 943 },
  { year: 1961, code: 944 },
  { year: 1962, code: 945 },
  { year: 1963, code: 946 },
  { year: 1964, code: 947 },
  { year: 1965, code: 948 },
  { year: 1966, code: 949 },
  { year: 1967, code: 950 },
  { year: 1968, code: 951 },
  { year: 1969, code: 952 },
  { year: 1970, code: 953 },
  { year: 1971, code: 954 },
  { year: 1972, code: 955 },
  { year: 1973, code: 956 },
  { year: 1974, code: 957 },
  { year: 1975, code: 958 },
  { year: 1976, code: 959 },
  { year: 1977, code: 960 },
  { year: 1978, code: 961 },
  { year: 1979, code: 962 },
  { year: 1980, code: 963 },
  { year: 1981, code: 964 },
  { year: 1982, code: 965 },
  { year: 1983, code: 966 },
  { year: 1984, code: 967 },
  { year: 1985, code: 968 },
  { year: 1986, code: 969 },
  { year: 1987, code: 970 },
  { year: 1988, code: 971 },
  { year: 1989, code: 972 },
  { year: 1990, code: 973 },
  { year: 1991, code: 974 },
  { year: 1992, code: 975 },
  { year: 1993, code: 976 },
  { year: 1994, code: 977 },
  { year: 1995, code: 978 },
  { year: 1996, code: 979 },
  { year: 1997, code: 980 },
  { year: 1998, code: 981 },
  { year: 1999, code: 982 },
  { year: 2000, code: 983 },
  { year: 2001, code: 984 },
  { year: 2002, code: 985 },
  { year: 2003, code: 986 },
  { year: 2004, code: 987 },
  { year: 2005, code: 988 },
  { year: 2006, code: 989 },
  { year: 2007, code: 990 },
  { year: 2008, code: 2903 },
  { year: 2009, code: 2904 },
  { year: 2010, code: 2905 },
  { year: 2011, code: 4363 },
  { year: 2012, code: 4364 },
  { year: 2013, code: 4365 },
  { year: 2014, code: 4366 },
  { year: 2015, code: 4367 },
  { year: 2016, code: 4368 },
  { year: 2017, code: 4369 },
  { year: 2018, code: 4370 },
  { year: 2019, code: 4371 },
  { year: 2020, code: 4372 },
  { year: 2021, code: 4373 },
  { year: 2022, code: 4374 },
  { year: 2023, code: 4375 },
  { year: 2024, code: 4376 },
  { year: 2025, code: 4377 },
  { year: 2026, code: 4378 },
  { year: 2027, code: 4379 },
  { year: 2028, code: 4380 },
  { year: 2029, code: 6831 },
  { year: 2030, code: 6832 },
  { year: 2031, code: 6833 },
  { year: 2032, code: 6834 },
  { year: 2033, code: 6835 },
  { year: 2034, code: 6836 },
  { year: 2035, code: 6837 },
  { year: 2036, code: 6838 },
  { year: 2037, code: 6839 },
  { year: 2038, code: 6840 },
  { year: 2039, code: 6841 },
  { year: 2040, code: 6842 },
];