<template>
  <div class="header">
    <div class="container">
      <nav class="navbar">
        <div class="navbar-left-side">
          <a :href="'https://evisa.express/' + currentLang">
            <img src="/assets/icon/evisa-express-logo.svg" class="main-navbar-logo">
          </a>
          <a :href="'https://evisa.express/' + currentLang" class="navbar-link evisa-link">
            <span>{{ $t('system.backToEvisaExpress') }}</span>
          </a>
        </div>
        <div class="navbar-right-side">
          <div class="navbar-email-wrapper">
            <a :href="`https://evisa.express/${currentLang}/contact`" class="navbar-link">
              <img src="/assets/icon/email-icon.svg" class="navbar-icon">
              <span>{{ $t('system.contactUs') }}</span>
            </a>
          </div>
          <button class="language-switcher-button" @click="languageSwitcherActive = true">
            <img src="/assets/icon/globe-icon.svg" class="navbar-icon">
            <span>{{ $t('languageSwitcher.' + currentLang) }}</span>
          </button>
          <div class="hamburger-menu">
            <button class="hamburger-menu-button" @click="hamburgerMenuActive = !hamburgerMenuActive">
              <img src="/assets/icon/hamburger-menu-icon.svg" class="hamburger-menu-icon" v-if="!hamburgerMenuActive">
              <img src="/assets/icon/close-icon-black.svg" class="close-icon" v-if="hamburgerMenuActive">
            </button>
          </div>
        </div>
        <div class="hamburger-menu-wrapper" v-if="hamburgerMenuActive">
          <div class="hamburger-menu-paper">
            <a :href="'https://evisa.express/' + currentLang" class="navbar-link evisa-link --mobile">
              <span>{{ $t('system.backToEvisaExpress') }}</span>
            </a>
            <a :href="'mailto:' + store.emailAddress" class="navbar-link --mobile">
              <img src="/assets/icon/email-icon.svg" class="navbar-icon">
              <span>{{ store.emailAddress }}</span>
            </a>
            <a :href="'tel:(+44) 2031 293 603'" class="navbar-link --mobile">
              <img src="/assets/icon/phone-icon.svg" class="navbar-icon">
              <span>(+44) 2031 293 603</span>
            </a>
            <button class="btn btn-white btn-language-switcher-mobile" @click="languageSwitcherActive = true">
              <img src="/assets/icon/globe-icon.svg" class="navbar-icon">
              <span>{{ $t('languageSwitcher.' + currentLang) }}</span>
            </button>
          </div>
        </div>
        <div v-if="languageSwitcherActive" class="language-switcher --shadowbox">
          <div class="language-switcher --paper">
            <div class="logo-wrapper">
              <img src="/assets/icon/logo-icon.svg">
              <button class="close-button" @click="languageSwitcherActive = false">
                <img src="/assets/icon/close-icon.svg">
              </button>
            </div>
            <h1 class="--title">{{ $t('languageSwitcher.title') }}</h1>
            <SelectInput
                :data="store.languageInputData"
                v-model:value="targetLang"
                :valid="true"
                :touched="true"
            />
            <div class="buttons-wrapper">
              <RouterLink :to="'/' + targetLang" class="btn btn-burgund btn-language-switcher" @click="languageSwitcherActive=false">
                {{ $t('languageSwitcher.save') }}
              </RouterLink>
              <button class="btn btn-white btn-cancel" @click="languageSwitcherActive = false">{{ $t('languageSwitcher.cancel') }}</button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useGeneralStore } from '../store/general';
import SelectInput from './form/fields/SelectInput.vue';

const store = useGeneralStore();
const route = useRoute();

const languageSwitcherActive = ref(false);
const hamburgerMenuActive = ref(false);

const currentLang = computed(() => {
  return route.params.lang ?? 'en';
});

const targetLang = ref(currentLang.value);
</script>